<template>
  <div v-if="this.$store.state.currentUser !== null">
    <div class="mb-3 mb-lg-2 mr-3">
      <h3 class="font-weight-bolder">
        Hi {{ this.$store.state.currentUser.name }}
      </h3>
      <span>Good to see you</span>
    </div>
    <admin
      v-if="this.$store.state.currentUser.role.code === 'sa'"
      :current-user="this.$store.state.currentUser"
    />
    <salesperson
      v-else-if="this.$store.state.currentUser.role.code === 'sp'"
      :current-user="this.$store.state.currentUser"
    />
    <superuser
      v-else-if="this.$store.state.currentUser.role.code === 'su'"
      :current-user="this.$store.state.currentUser"
    />
  </div>
  <div v-else>
    <b-overlay
      :show="overlay"
      variant="light"
      :opacity="0.7"
      blur="2px"
      rounded="sm"
    />
  </div>
</template>

<script>
import store from '@/store'
import {
  BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Admin from './components/Admin.vue'
import Salesperson from './components/Salesperson.vue'
import Superuser from './components/Superuser.vue'

export default {
  components: {
    BOverlay,
    Superuser,
    Admin,
    Salesperson,
  },
  data() {
    return {
      overlay: true,
    }
  },
  created() {
    this.adjustCurrentUser()
  },
  methods: {
    async adjustCurrentUser() {
      await store.dispatch('app-user/getSelf')
        .then(() => {
          this.overlay = false
        })
        .catch(error => {
          if (error.response.status === 401){
            // removed token from localStorage.
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')
            this.$router.push({ name: 'auth-login' })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error has occured',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          }
        })
    },
  },
}
</script>

<style>

</style>
