<template>
  <b-row v-if="salespersonLeadDataExist === true">
    <b-col
      md="12"
    >
      <div class="d-flex justify-content-center">
        <b-spinner
          v-show="dataLandingPageLoaded == true"
          label="Loading..."
          :variant="'primary'"
        />
      </div>

      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2 d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="search"
            type="search"
            class="d-inline-block mr-1 search-bar"
            placeholder="Search..."
          />
        </div>
        <b-table
          ref="refFormListTable"
          class="position-relative"
          :items="landingPageLists"
          responsive
          :fields="tableColumns"
          :filter="search"
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(landing_page)="data">
            <b-media
              class="media-lp"
              vertical-align="center"
              style="font-weight: 500"
            >
              <b-link
                class="truncate"
                @click="redirectToEntriesLead(data.item, 'entries')"
              >
                {{ data.item.title }}
              </b-link>
              <b-link
                class="font-small-3 truncate"
                :to="{ name: 'landing-pages-display-by-slug', params: { slug: data.item.slug } }"
                target="_blank"
              >
                https://tracebit.net/lp/{{ data.item.slug }}
              </b-link>
            </b-media>
          </template>
          <template #cell(new_entries)="data">
            <b-media vertical-align="center">
              <b-button
                variant="link"
                @click="redirectToEntriesLead(data.item, 'new')"
              >
                {{ data.item.new_form_inputs_count }}
              </b-button>
            </b-media>
          </template>
          <template #cell(hot_lead)="data">
            <b-media vertical-align="center">
              <b-button
                variant="link"
                @click="redirectToEntriesLead(data.item, 'hotlead')"
              >
                {{ data.item.hotlead_form_inputs_count }}
              </b-button>
            </b-media>
          </template>
          <template #cell(on_process)="data">
            <b-media vertical-align="center">
              <b-button
                variant="link"
                @click="redirectToEntriesLead(data.item, 'on_progress')"
              >
                {{ data.item.on_process_form_inputs_count }}
              </b-button>
            </b-media>
          </template>
          <template #cell(entry_rate)="data">
            <b-media vertical-align="center">
              {{ (data.item.form_inputs_count / data.item.visitor)*100 | formatNumber }} %
            </b-media>
          </template>
          <template #cell(sales_target)="data">
            <b-media vertical-align="center">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.bottom="formatCurrencyString(data.item.total_deal_price) +' / '+ formatCurrencyString(data.item.sales_goal_deal)"
                variant="flat-primary"
                size="md"
                class="p-0"
              >
                {{ (data.item.total_deal_price / data.item.sales_goal_deal)*100 | formatNumber }} %
              </b-button>
            </b-media>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'landing-pages-display-by-slug', params: { slug: data.item.uncount_slug } }"
                target="_blank"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <span class="align-middle ml-50">Add Leads Manually</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
  <div
    v-else-if="salespersonLeadDataExist === false"
    class="d-flex justify-content-center"
  >
    <b-spinner
      label="Loading..."
      :variant="'primary'"
    />
  </div>
  <empty-page
    v-else
    :empty-kind-page="'sp-dashboard'"
    :header-string="''"
    :content-string="'Welcome to TraceBit. We are so happy to have you. You haven’t assigned in any Leads. Please contact your superior to get you started.'"
    link-to-name=""
  />
</template>

<script>
import {
  BDropdown, BDropdownItem, BRow, BCol, BCard, BFormInput, BButton, BTable, BSpinner, VBTooltip, BLink, BMedia,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import EmptyPage from '@/views/menus/EmptyPage.vue'
import newSalespersonChartTheme from '@/views/menus/dashboard/components/salespersonChartTheme.json'

const numeral = require('numeral')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    EmptyPage,
    BLink,
    BMedia,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  filters: {
    formatNumber(value) {
      return numeral(value).format('0.00')
    },
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      newSalespersonChartTheme,
      landingPageLists: [],
      leadsStatuses: [],
      salespersonLeadDataExist: false,
      search: null,
      dataLandingPageLoaded: true,
      sortBy: '',
      sortDesc: false,
      tableColumns: [
        {
          key: 'landing_page',
          label: 'Landing Page',
          sortable: true,
          thClass: 'align-middle',
        },
        {
          key: 'new_entries',
          label: 'New',
          sortable: true,
          thClass: 'align-middle text-center',
          tdClass: 'text-right',
        },
        {
          key: 'hot_lead',
          label: 'Hot Leads',
          sortable: true,
          thClass: 'align-middle text-center',
          tdClass: 'text-right',
        },
        {
          key: 'on_process',
          label: 'On Process',
          sortable: true,
          thClass: 'align-middle text-center',
          tdClass: 'text-right',
        },
        {
          key: 'entry_rate',
          label: 'Entry Rate',
          sortable: true,
          thClass: 'align-middle text-center',
          tdClass: 'text-right',
        },
        {
          key: 'sales_target',
          label: 'Sales Target',
          sortable: true,
          thClass: 'align-middle text-center',
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          thClass: 'align-middle text-center',
        },
      ],
    }
  },
  created() {
    this.fetchLandingPages()
    this.fetchAllLeadStatuses()
    this.getLeadHistoryCountOfSalesperson()
  },
  methods: {
    redirectToEntriesLead(landingPage, statusLabel) {
      this.$store.state.leadTabs = 'entries'
      if (statusLabel === 'hotlead') {
        this.$store.state.hotlead = true
      } else if (statusLabel !== 'entries') {
        const leadStatusFiltered = this.leadsStatuses.filter(el => {
          return el.status_label === statusLabel
        })
        this.$store.state.leadStatusEntries = leadStatusFiltered[0].id
      }
      this.$router.push({ name: 'leads-landing-page', params: { uuid: landingPage.uuid } })
    },
    async fetchAllLeadStatuses() {
      await store.dispatch('menu-lead/getAllLeadStatuses')
        .then(response => {
          this.leadsStatuses = response.data.data
        })
    },
    async fetchLandingPages() {
      await store.dispatch('menu-dashboard/getTopActiveLandingPage', {
        salesperson_id: this.currentUser.id,
      })
        .then(response => {
          this.landingPageLists = response.data
          this.dataLandingPageLoaded = false
        })
    },
    async getLeadHistoryCountOfSalesperson() {
      await store.dispatch('menu-dashboard/getLeadHistoryCountOfSalesperson', {
        id: this.currentUser.id,
      })
        .then(response => {
          this.salespersonLeadDataExist = response.data.data > 0 ? true : null
        })
    },
    formatCurrencyString(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style>
  @media (max-width: 500px) {
    .truncate {
      width: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .search-bar {
      width: 80%;
    }
  }
  @media (min-width: 500px) {
    .search-bar {
      width: 30%;
    }
  }
  .media-lp .media-body {
    display: flex;
    flex-direction: column;
  }
</style>
