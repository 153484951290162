const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: 'rgba(40,218,198,0.8)',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  latestBarChart: {
    type: 'bar',
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: chartColors.successColorShade,
        },
      ],
    },
    options: {
      scales: {
        xAxes: [{
          gridLines: {
            display: true,
            drawTicks: true,
            drawOnChartArea: false,
          },
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            min: 0,
          },
        }],
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
  },
}
