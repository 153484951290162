<template>
  <div
    class="misc-inner p-2 p-sm-3"
  >
    <div class="w-100 text-center">
      <b-img
        v-if="emptyKindPage === 'form-manager'"
        fluid
        :src="require('@/assets/images/logo/form-empty.png')"
        alt="Empty page"
      />
      <b-img
        v-else-if="emptyKindPage === 'landing-page'"
        fluid
        :src="require('@/assets/images/logo/lp-empty.png')"
        alt="Empty page"
      />
      <b-img
        v-else-if="emptyKindPage === 'lead'"
        fluid
        :src="require('@/assets/images/logo/lead-empty.png')"
        alt="Empty page"
      />
      <b-img
        v-else-if="emptyKindPage === 'sp-dashboard'"
        fluid
        :src="require('@/assets/images/logo/sp-empty.png')"
        alt="Empty page"
      />
      <b-img
        v-else-if="emptyKindPage === 'su-dashboard'"
        fluid
        :src="require('@/assets/images/logo/su-empty.png')"
        alt="Empty page"
      />
      <h3 class="mb-1">
        {{ headerString }}
      </h3>
      <div class="d-flex justify-content-center col-md-4 offset-md-4">
        <p class="mb-3 text-justify">
          {{ contentString }}
        </p>
      </div>

      <b-button
        v-if="linkToName !== ''"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        :to="{ name: linkToName }"
        pill
      >
        Create New {{ emptyKindPage.replace(/-/g, ' ') }}
      </b-button>

    </div>
  </div>
</template>

<script>
import {
  BImg,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    emptyKindPage: {
      type: String,
      required: true,
    },
    headerString: {
      type: String,
      default: '',
    },
    contentString: {
      type: String,
      default: '',
    },
    linkToName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>
