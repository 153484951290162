<template>
  <div v-if="superuserDashboardDataLPExist === true">
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card title="Campaign Monitor">
          <b-card-text>
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="border-right"
              >
                <div>
                  <h5>Rank of landing pages based on:</h5>
                  <v-select
                    id="vue-select"
                    v-model="selectedParam"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="options"
                    :reduce="label => label.value"
                    :clearable="false"
                    class="dropdown"
                    @input="fetchTopLP()"
                  />
                </div>
                <div class="pt-1">
                  <b-spinner
                    v-if="topLandingPageLoadingState == true"
                    class="justify-content-center text-center"
                    label="Loading..."
                    variant="'primary'"
                  />
                  <table
                    v-else
                    class="w-100"
                  >
                    <tr
                      v-for="(channel, index) in topLandingPage"
                      :key="index"
                    >
                      <!-- <td>&#8226; &nbsp;</td> -->
                      <td>{{ channel.label }}</td>
                      <td class="text-right">
                        <span v-if="selectedParam !== 'total_submission' && selectedParam !== 'total_deal'">{{ channel.percentage | formatNumber }}%</span>
                        <span v-else>{{ channel.percentage }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <div class="pt-1">
                  <h5>Marketing Channel Performance</h5>
                  <table
                    class="w-100 d-block"
                    style="height: 115px; overflow-y: auto;"
                  >
                    <tr
                      v-for="(channel, index) in topMarketingChannel"
                      :key="index"
                    >
                      <td>{{ index+1 }}.&nbsp;</td>
                      <td>{{ channel.name }} ({{ channel.landing_page }})</td>
                      <td class="text-right">
                        {{ channel.visitor_count }} submission
                      </td>
                    </tr>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card
          title="Landing Page Submission Performance"
          class="mt-2"
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <v-select
                id="vue-select-lp"
                v-model="selectedLandingPageSubmission"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="allLandingPages"
                :reduce="label => label.uuid"
                :clearable="false"
                class="dropdown"
                @input="fetchTotalMonthlySubmission(submissionYear)"
              />
            </b-col>
            <b-col
              cols="12"
              sm="2"
            >
              <v-select
                id="vue-select-year"
                v-model="submissionYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="submissionYearOption"
                :clearable="false"
                class="dropdown"
                @input="fetchTotalMonthlySubmission($event)"
              />
            </b-col>
            <b-col class="manage-landing-page">
              <b-button
                variant="outline-primary"
                pill
                :to="{ name: 'landing-pages-list' }"
              >
                Manage Landing Page
              </b-button>
            </b-col>
          </b-row>
          <div
            v-if="landingPageSubmissionMonthly !== null"
            class="mt-1"
          >
            <canvas
              id="landingPageSubmission"
              height="400"
              style="max-height: 440px;"
            />
          </div>
        </b-card>
        <b-card
          title="Sales Deal"
          class="mt-2"
        >
          <b-row>
            <b-col
              cols="12"
              sm="2"
              class="mb-1"
            >
              <v-select
                id="vue-select"
                v-model="selectedSalesActivityYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="yearOptions"
                :clearable="false"
                class="dropdown"
                @input="fetchDealPerYearChart"
              />
            </b-col>
            <!-- <b-col
              align-self="end"
              class="text-right mb-1"
            >
              <b-button
                variant="outline-primary"
                pill
              >
                View Leads
              </b-button>
            </b-col> -->
          </b-row>
          <app-echart-bar
            v-if="optionSalesActivities.xAxisData.length > 0"
            :option-data="optionSalesActivities"
          />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <div class="card">
          <div class="card-header">
            <div>
              <h4 class="card-title">
                Top Salesperson
              </h4>
            </div>
          </div>
          <div
            class="table-content"
            style="overflow-x:auto;"
          >
            <b-table
              responsive
              :items="topSalespersonDataChart"
              :fields="fieldsTopSalesperson"
              class="mb-0"
              table-class="text-nowrap"
              style="position: relative;"
            >
              <template #cell(name)="data">
                <b-media vertical-align="center">
                  <div class="font-weight-bold">
                    {{ data.item.name }}
                  </div>
                  <div class="font-small-3">
                    Total leads: {{ data.item.salesperson_histories_count }}
                  </div>
                </b-media>
              </template>
              <template #cell(salesperson_conversion)="data">
                <b-media
                  v-if="data.item.salesperson_deal_leads_count === 0"
                  vertical-align="center"
                >
                  0 %
                </b-media>
                <b-media
                  v-else
                  vertical-align="center"
                >
                  {{ (data.item.salesperson_deal_leads_count / data.item.salesperson_histories_count)*100 | formatNumber }} %
                </b-media>
              </template>
            </b-table>
          </div>
          <div class="card-footer mt-1">
            <table>
              <tr>
                <td style="padding-right: 5px;">
                  OC
                </td>
                <td>=</td>
                <td class="pl-1">
                  On Process
                </td>
              </tr>
              <tr>
                <td style="padding-right: 5px;">
                  D
                </td>
                <td>=</td>
                <td class="pl-1">
                  Deal
                </td>
              </tr>
              <tr>
                <td style="padding-right: 5px;">
                  F
                </td>
                <td>=</td>
                <td class="pl-1">
                  Fail
                </td>
              </tr>
              <tr>
                <td style="padding-right: 5px;">
                  SC
                </td>
                <td>=</td>
                <td class="pl-1">
                  Sales Conversion
                </td>
              </tr>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
  <b-container v-else-if="superuserDashboardDataLPExist === false">
    <div class="d-flex justify-content-center">
      <b-spinner
        label="Loading..."
        variant="'primary'"
      />
    </div>
  </b-container>
  <b-container v-else>
    <b-row class="d-flex justify-content-center">
      <b-col
        cols="12"
        md="5"
        class="d-none d-lg-flex align-items-center p-lg-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="require('@/assets/images/logo/su-dashboard.png')"
            alt="Dashboard Image"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        md="7"
      >
        <div class="text-header">
          <h2 class="font-weight-bolder">
            Keep up your sales team performace fly high and get real-time measurments in no time with just simple steps.
          </h2>
          <h3>
            Questions? Contact us to <a href="mailto:hello@tracebit.net">hello@tracebit.net</a>
          </h3>
        </div>
        <div class="text-body mt-2 d-flex flex-column align-items-start">
          <div class="text-form d-flex py-1">
            <b-avatar
              variant="secondary"
              text="1"
            />
            <div class="d-flex flex-column pl-1">
              <h3 class="font-weight-bolder">
                Create Form for visitors to fill.
              </h3>
              <b-button
                variant="outline-primary"
                pill
                :to="{ name: 'form-managers-create' }"
                style="width: fit-content;"
              >
                Create New Form
              </b-button>
            </div>
          </div>
          <div class="text-landing-pages d-flex py-1">
            <b-avatar
              variant="secondary"
              text="2"
            />
            <div class="d-flex flex-column pl-1">
              <h3 class="font-weight-bolder">
                Add Salesperson to take care the leads.
              </h3>
              <b-button
                variant="outline-primary"
                pill
                :to="{ name: 'user-setting' }"
                style="width: fit-content;"
              >
                Add Salesperson
              </b-button>
            </div>
          </div>
          <div class="text-salesperson d-flex py-1">
            <b-avatar
              variant="secondary"
              text="3"
            />
            <div class="d-flex flex-column pl-1">
              <h3 class="font-weight-bolder">
                Create Landing Page, attach the Form and choose your Salespersons to take care each landing page.
              </h3>
              <b-button
                variant="outline-primary"
                pill
                :to="{ name: 'landing-pages-create' }"
                style="width: fit-content;"
              >
                Create New Landing Page
              </b-button>
            </div>
          </div>
          <div class="text-lead d-flex py-1">
            <b-avatar
              variant="secondary"
              text="4"
            />
            <div class="d-flex flex-column pl-1">
              <h3 class="font-weight-bolder">
                Manage the incoming Leads.
              </h3>
              <b-button
                variant="outline-primary"
                pill
                :to="{ name: 'leads-list' }"
                style="width: fit-content;"
              >
                Go to Lead
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton, BTable, BMedia, BContainer, BSpinner, BAvatar, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
// import numeral from 'numeral'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import newSalespersonChartTheme from '@/views/menus/dashboard/components/salespersonChartTheme.json'
import newSalesActivityChartTheme from '@/views/menus/dashboard/components/salesActivityChartTheme.json'
import chartjsSuperuserData from '@/views/menus/dashboard/components/chartjsSuperuserData'
import Ripple from 'vue-ripple-directive'
import Chart from 'chart.js'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BCardText,
    vSelect,
    BMedia,
    AppEchartBar,
    BTable,
    BContainer,
    BAvatar,
    BImg,
  },
  filters: {
    formatNumber(value) {
      const number = value.toFixed(2)
      const result = (number - Math.floor(number)) !== 0
      return result ? number : value
    },
  },
  directives: {
    Ripple,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      canvaslandingPageSubmission: null,
      chartlandingPageSubmission: null,
      newSalesActivityChartTheme,
      newSalespersonChartTheme,
      superuserDashboardDataLPExist: false,
      superuserDashboardDataFormExist: false,
      landingPageSubmissionMonthly: null,
      topSalespersonDataChart: null,
      overlay: true,
      topLandingPage: [],
      topLandingPageLoadingState: false,
      topMarketingChannel: [],
      allLandingPages: [],
      yearOptions: [],
      submissionYear: new Date().getFullYear(),
      submissionYearOption: [
        2020,
        2021,
        2022,
        2023,
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030,
      ],
      onGoingCampaignCount: [],
      options: [
        { label: 'Conversion Rate', value: 'conversion_rate' },
        { label: 'Sales Target', value: 'sales_goal_deal' },
        { label: 'Leads Target', value: 'submission_goal_deal' },
        { label: 'Total Deal', value: 'total_deal' },
        { label: 'Total Submission', value: 'total_submission' },
      ],
      fieldsTopSalesperson: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'salesperson_new_leads_count',
          sortable: true,
          label: 'N',
          tdClass: 'text-right',
          thClass: 'text-right',
        },
        {
          key: 'salesperson_on_process_leads_count',
          sortable: true,
          label: 'OP',
          tdClass: 'text-right',
          thClass: 'text-right',
        },
        {
          key: 'salesperson_deal_leads_count',
          sortable: true,
          label: 'D',
          tdClass: 'text-right',
          thClass: 'text-right',
        },
        {
          key: 'salesperson_fail_leads_count',
          sortable: true,
          label: 'F',
          tdClass: 'text-right',
          thClass: 'text-right',
        },
        {
          key: 'salesperson_conversion',
          sortable: true,
          label: 'SC',
          tdClass: 'text-right',
          thClass: 'text-right',
        },
      ],
      optionSalesActivities: {
        xAxisData: [],
        series: [],
      },
      selectedParam: null,
      selectedSalesActivityYear: new Date().getFullYear(),
      selectedLandingPageSubmission: null,
    }
  },
  mounted() {
    this.selectedParam = 'conversion_rate'
    this.generateLast5Years()
    this.fetchAllLandingPages()
    this.fetchTopSubmissionMarketingChannel()
    this.fetchDealPerYearChart()
    this.fetchTopSalespersonLeadDetail()
    this.fetchTopLP()
  },
  methods: {
    async fetchAllLandingPages() {
      await store.dispatch('menu-lead/getAllLandingPages', {
        user_id: this.currentUser.id,
      })
        .then(response => {
          this.allLandingPages = response.data
          Object.entries(response.data).forEach(value => {
            this.selectedLandingPageSubmission = value[1].uuid
          })
          this.fetchTotalMonthlySubmission(this.submissionYear)
          if (response.data.length > 0){
            this.superuserDashboardDataLPExist = true
          } else {
            this.superuserDashboardDataLPExist = null
          }
        })
    },
    async fetchAllForms() {
      await store.dispatch('menu-form/getAllForms')
        .then(response => {
          if (response.data.data.length > 0){
            this.superuserDashboardDataFormExist = true
          } else {
            this.superuserDashboardDataFormExist = null
          }
        })
    },
    async generateLast5Years() {
      const max = new Date().getFullYear()
      const min = max - 5
      for (let i = max; i >= min; i -= 1) {
        this.yearOptions.push(i)
      }
    },
    async fetchTopSalespersonLeadDetail() {
      await store.dispatch('menu-dashboard/getTopSalespersonLeadDetail', {
        superuser_id: this.currentUser.id,
      })
        .then(response => {
          this.topSalespersonDataChart = response.data.data.salesperson
        })
    },
    async fetchTopLP() {
      this.topLandingPageLoadingState = true
      await store.dispatch('menu-dashboard/getTopLandingPage', {
        top_kind: this.selectedParam,
        // limit: 3,
        user_id: this.$store.state.currentUser.id,
      })
        .then(response => {
          this.topLandingPage = response.data.data
          this.topLandingPageLoadingState = false
        })
    },
    async fetchDealPerYearChart() {
      await store.dispatch('menu-dashboard/getDealPerYearChart', {
        year: this.selectedSalesActivityYear,
        user_id: this.$store.state.currentUser.id,
      })
        .then(response => {
          this.optionSalesActivities.xAxisData = response.data.data.month
          this.optionSalesActivities.series = response.data.data.landing_pages
          if (response.data.data.years.length > 0) {
            this.yearOptions = response.data.data.years
          }
        })
    },
    async fetchTopSubmissionMarketingChannel() {
      await store.dispatch('menu-dashboard/getTopSubmissionMarketingChannel', {
        // limit: 5,
        user_id: this.$store.state.currentUser.id,
      })
        .then(response => {
          this.topMarketingChannel = response.data.data
        })
    },
    async fetchTotalMonthlySubmission(year) {
      this.submissionYear = year
      await store.dispatch('menu-dashboard/getTotalMonthlySubmission', {
        uuid: this.selectedLandingPageSubmission,
        year: this.submissionYear,
      })
        .then(response => {
          this.landingPageSubmissionMonthly = chartjsSuperuserData
          this.landingPageSubmissionMonthly.latestBarChart.data.datasets[0].data = response.data.data.total
          this.landingPageSubmissionMonthly.latestBarChart.data.labels = response.data.data.month
        })

      if (this.chartlandingPageSubmission != null) {
        this.chartlandingPageSubmission.destroy()
      }
      this.canvaslandingPageSubmission = document.getElementById('landingPageSubmission').getContext('2d')
      this.chartlandingPageSubmission = new Chart(this.canvaslandingPageSubmission, this.landingPageSubmissionMonthly.latestBarChart)
      this.chartlandingPageSubmission.update()
    },
  },
}
</script>

<style>
.dropdown input {
  cursor: pointer;
}

.manage-landing-page {
  text-align: right;
}

@media screen and (max-width: 576px) {
  .manage-landing-page {
    width: 100%;
    text-align: left;
    margin-top: 1rem;
  }
}
</style>
